<template>
  <navbar
    position="fixed"
    type="white"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img class="logo" src="../assets/img/logo.png" alt="">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;Serenity Resources Pte. Ltd.</p>
        <!-- Serenity -->
      </router-link>
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Designed by Invision. Coded by Creative Tim
        </div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link v-popover:popover1 to="/">
          <p class="nav-link btn-round">Home</p>
        </router-link>
      </li>
      <li class="nav-item">
        <p class="navbar-brand"> | </p>
      </li>
      <li class="nav-item">
        <router-link v-popover:popover1 to="/about">
          <p class="nav-link btn-round">About Us</p>
        </router-link>
      </li>
      <li class="nav-item">
        <p class="navbar-brand"> | </p>
      </li>
      <li class="nav-item">
        <router-link v-popover:popover1 to="/product">
          <p class="nav-link btn-round">Products &#38; Services</p>
        </router-link>
      </li>
      <li class="nav-item">
        <p class="navbar-brand"> | </p>
      </li>
      <!-- <drop-down
              tag="li"
              title="Examples"
              class="nav-item"
      >
        <nav-link to="/landing">
          <i class="now-ui-icons education_paper"></i> Landing
        </nav-link>
        <nav-link to="/login">
          <i class="now-ui-icons users_circle-08"></i> Login
        </nav-link>
        <nav-link to="/profile">
          <i class="now-ui-icons users_single-02"></i> Profile
        </nav-link>
      </drop-down> -->

      <li class="nav-item">
        <router-link v-popover:popover1 to="/contact">
          <p class="nav-link btn-round">Contact Us</p>
        </router-link>
      </li>

      <!-- <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href="https://twitter.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/CreativeTim"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/CreativeTimOfficial"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li> -->
    </template>
  </navbar>
</template>

<script>
// import { DropDown, Navbar, NavLink } from '@/components';
import { Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    // DropDown,
    Navbar,
    // NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped>
.navbar-brand p{
  font-size: 16px;
  font-weight: 700;
}
.logo{
  padding: 0;
  margin: 0;
  border-radius: 10px;
  max-height: 50px;
  /* background: linear-gradient(to bottom, rgb(141, 115, 115, 0.6), 
                            rgb(141, 115, 115, 0.5), 
                            rgb(141, 115, 115, 0.3),
                            rgb(0, 0, 0, 0)); */
}
</style>
