<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/sg6.jpeg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Products and Services</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Our Products and Services</h2>
            <h5 class="description" style="font-size:20px;font-weight:900">
              Crude, Refined products and Petrochemical 
              <br>
              Base Metal
              <br>
              Bunkering
              <br>
              Biofuel and Renewables Commodity  
              <br>
              Risk Management and Financing 
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-6" style="margin-bottom:300px">
              <!-- <div
                class="image-container image-left"
                style="background-image: url('img/sg1.jpeg')"
              > -->
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary" style="width:730px; right:0px; margin-bottom:400px; top:0px; font-weight:600">
                  <span style="font-weight:600;font-size:25px">Our Advantages</span>
                  <br>
                  <br>
                  1. Long-standing expertise in trading oil and a team of experienced outstanding traders.
                  <br>
                  2. Advantage of arbitrages that arise from often-significant price differentials that exist between various countries.
                  <br>
                  3. Close relationship with important industry partners that provides assurance and flexibility of delivery
                  <br>
                  4. Capability of structure and execute a variety of hedging solutions across all major energy trading exchanges to help our client manage exposure effectively.
                  <br>
                  <br>
                </p>
              <!-- </div> -->
              <!-- Second image on the left side of the article -->
              <!-- <div
                class="image-container"
                style="background-image: url('img/sg4.jpeg')"
              ></div> -->
            </div>
            <!-- <div class="col-md-5"> -->
              <!-- First image on the right side, above the article -->
              <!-- <div
                class="image-container image-right"
                style="background-image: url('img/sg5.jpeg')"
              ></div> -->
              <!-- <h3>
                Our Mission
              </h3>
              <p>
                Strive to provide the most efficient, professional and reliable trading, financial and logistics services and price risk management tools to commodity producers, consumers, traders as well the investors in the region.
              </p> -->
            <!-- </div> -->
          </div>
          <div class="col-md-10 ml-auto mr-auto">
            <div class="row collections">
              <div class="col-md-6">
                <img src="img/bg6.jpg" class="img-raised" style="margin-top:120px"/>
                <img src="img/bg11.jpg" alt="" class="img-raised mt-5" />
              </div>
              <div class="col-md-6">
                <img src="img/bg7.jpg" alt="" class="img-raised" style="margin-top:120px"/>
                <img src="img/bg8.jpg" alt="" class="img-raised mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Here is our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Want to work with us?</h2>
        <p class="description">Your project is very important to us.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="First Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                >Send Message</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'product',
  bodyClass: 'product-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
