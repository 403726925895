<template>
  <div> 
    <!-- class="page-header clear-filter" filter-color=""> -->
    <!-- <div
      class="page-header-image"
      style="background-image: url('img/sg2.jpeg')"
    ></div> -->
    <div class="page-header page-header-small-plus">
      <parallax
        class="page-header-image"
        style="background-image: url('img/sg2.jpeg');"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Get in touch with us</h1>
        </div>
      </div>
    </div>
    <nucleo-icons-section></nucleo-icons-section>
    <!-- <contact-us></contact-us> -->
    <!-- <main-footer></main-footer> -->
  </div>
</template>



<script>
import { Button, FormGroupInput } from '@/components';
import NucleoIconsSection from './components/NucleoIconsSection';
// import ContactUs from './components/ContactUs';
// import MainFooter from '@/layout/MainFooter';
export default {
  name: 'contact-page',
  bodyClass: 'contact-page',
  components: {
    NucleoIconsSection,
    // ContactUs,
    // MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  }
};
</script>
<style></style>
