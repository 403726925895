<template>
  <div>
    <div class="page-header clear-filter" filter-color="black">
      <parallax
        class="page-header-image-main"
        style="background-image:url('img/sg4.jpeg');"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <!-- <img class="n-logo" src="img/now-logo.png" alt="" /> -->
          <div style="padding-top:20vh">
            <h1 class="title">Serenity Resources Pte. Ltd.</h1>
            <h4>a fast-growing and active commodity trading company </h4>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="main">
      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/hero-image-1.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/hero-image-2.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/hero-image-3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <basic-elements></basic-elements>
    <navigation></navigation>
    <tabs-section></tabs-section>
    <progress-pagination></progress-pagination>
    <notifications></notifications>
    <typography></typography>
    <javascript-components></javascript-components>
    <carousel-section></carousel-section>
    <nucleo-icons-section></nucleo-icons-section> -->
    <div class="section">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h2 class="title">Who we are?</h2>
            <h5 class="description" style="font-weight:600">
              Established in 2019 in Singapore, Serenity Resources Pte Ltd is a fast-growing Singapore commodity trading house covering a wide range of energy products. We work with international partners to support and supply global energy flows, moving energy responsibly, safely and cost effectively around the world. We commit people, capital and ideas to help our clients and trading partners grow together with us. 
            </h5>
            <router-link v-popover:popover1 to="/about">
              <p class="btn btn-round btn-lg"
              target="_blank">About Us</p>
            </router-link>
            <!-- <h2>Why should you do business with us?</h2>
            <h5 class="description">
              Our main business is commodity trading, hedging and financing and main clients are refineries, trading house, suppliers, and end users in South East Asia and Greater China region.
            </h5>
            <router-link v-popover:popover1 to="/landing">
              <p class="btn btn-round btn-lg"
              target="_blank">Products and services</p>
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <signup-form></signup-form> -->
    <!-- <examples-section></examples-section> -->
    <download-section></download-section>
  </div>
</template>
<script>
import { Parallax } from '@/components';

// import BasicElements from './components/BasicElementsSection';
// import Navigation from './components/Navigation';
// import TabsSection from './components/Tabs';
// import ProgressPagination from './components/ProgressPagination';
// import Notifications from './components/Notifications';
// import Typography from './components/Typography';
// import JavascriptComponents from './components/JavascriptComponents';
// import CarouselSection from './components/CarouselSection';
// import NucleoIconsSection from './components/NucleoIconsSection';
// import SignupForm from './components/SignupForm';
// import ExamplesSection from './components/ExamplesSection';

import DownloadSection from './components/DownloadSection';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,

    // BasicElements,
    // Navigation,
    // TabsSection,
    // ProgressPagination,
    // Notifications,
    // Typography,
    // JavascriptComponents,
    // CarouselSection,
    // NucleoIconsSection,
    // SignupForm,
    // ExamplesSection,
    
    DownloadSection
  }
};
</script>
<style></style>
