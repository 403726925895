<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12" style="margin-left: 80px;margin-top:0px">
          <h2 class="title2">Where to find us:</h2>
          <h5 class="">
            7 Temasek Boulevard #08-03, Suntec Tower One
            <br />
            Singapore 038987
          </h5>
          <h2 class="title2">Get in touch:</h2>
          <h5 class="">
            Email: <img style="height: 24px;" src="../../assets/img/email.png" alt="">
            <br>
            Tel: +65 6980 7062
            <br>
            Fax: +65 6980 7064
          </h5>
          <!-- <div class="nucleo-container">
                    <img v-lazy="'assetsimg/nucleo.svg'" alt="">
                </div> -->
          <!-- <a
            href="https://demos.creative-tim.com/now-ui-kit/nucleo-icons.html"
            class="btn btn-primary btn-round btn-lg"
            target="_blank"
            >View Demo Icons</a
          >
          <a
            href="https://nucleoapp.com/?ref=1712"
            class="btn btn-primary btn-simple btn-round btn-lg"
            target="_blank"
            >View All Icons</a
          > -->
        </div>
        <div style="width: 400px; height: 300px; margin-top:20px; float: right;"><iframe style="border: 0;" src="https://maps.google.com/maps?q=Suntec%20Tower%20One,%20Singapore&t=&z=13&ie=UTF8&iwloc=&output=embed" width="400" height="300" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>
        <!-- <div class="col-lg-6 col-md-12">
          <div class="icons-container">
            <i class="now-ui-icons ui-1_send"></i>
            <i class="now-ui-icons ui-2_like"></i>
            <i class="now-ui-icons transportation_air-baloon"></i>
            <i class="now-ui-icons text_bold"></i>
            <i class="now-ui-icons tech_headphones"></i>
            <i class="now-ui-icons emoticons_satisfied"></i>
            <i class="now-ui-icons shopping_cart-simple"></i>
            <i class="now-ui-icons objects_spaceship"></i>
            <i class="now-ui-icons media-2_note-03"></i>
            <i class="now-ui-icons ui-2_favourite-28"></i>
            <i class="now-ui-icons design_palette"></i>
            <i class="now-ui-icons clothes_tie-bow"></i>
            <i class="now-ui-icons location_pin"></i>
            <i class="now-ui-icons objects_key-25"></i>
            <i class="now-ui-icons travel_istanbul"></i>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
