<template>
  <div
    class="section"
    id="#download-section"
    data-background-color="black"
  >
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="text-center col-md-12 col-lg-8">
          <h2 class="title">Why should you do business with us?</h2>
          <h5 class="description" >
            Serenity Resources Pte Ltd is a fast-growing and active commodity trading company incorporated in Singapore in Mar 2019, with Paid-up capital of USD 10million dollars. Our main business is commodity trading, hedging and financing and main clients are refineries, trading house, suppliers, and end users in South East Asia and Greater China region.
          </h5>
          <router-link v-popover:popover1 to="/product">
            <p class="btn btn-round btn-lg btn-simple"
            target="_blank">Products and Services</p>
          </router-link>
        </div>
      </div>
      <br />
      <br />
      <!-- <div class="row justify-content-md-center sharing-area text-center">
        <div class="text-center col-md-12 col-lg-8">
          <h3>Thank you for supporting us!</h3>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <a
            target="_blank"
            href="https://www.twitter.com/creativetim"
            class="btn btn-neutral btn-icon btn-twitter btn-round btn-lg"
            rel="tooltip"
            title="Follow us"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/creativetim"
            class="btn btn-neutral btn-icon btn-facebook btn-round btn-lg"
            rel="tooltip"
            title="Like us"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company-beta/9430489/"
            class="btn btn-neutral btn-icon btn-linkedin btn-lg btn-round"
            rel="tooltip"
            title="Follow us"
          >
            <i class="fab fa-linkedin"></i>
          </a>
          <a
            target="_blank"
            href="https://github.com/creativetimofficial/vue-now-ui-kit"
            class="btn btn-neutral btn-icon btn-github btn-round btn-lg"
            rel="tooltip"
            title="Star on Github"
          >
            <i class="fab fa-github"></i>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
